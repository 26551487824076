<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card v-if="!hideUI" elevation="1" class="mb-6">
          <v-card-title>Notifications</v-card-title>
          <v-card-subtitle
            >Once per day, we'll send you a list of new documents and helpful
            resources. You may manage your notification preferences
            <router-link to="/profile">here</router-link>.
          </v-card-subtitle>
          <v-card-title>
            <v-row>
              <v-col
                ><v-select
                  v-model="filterStatus"
                  :items="filterStatusItems"
                  label="Show Read/Unread"
                  clearable
                ></v-select
              ></v-col>
              <v-col
                ><v-select
                  v-model="dateRange"
                  :items="dateRanges"
                  label="Date"
                  clearable
                ></v-select
              ></v-col>
              <v-col>
                <v-btn outlined color="indigo" @click="updateAllAsRead"
                  >Mark All as Read</v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headersNotifications"
            :items="compNotifications"
            hide-default-footer 
            disable-pagination
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  v-bind:class="{ unread: item.status === 'unread' }"
                >
                  <td>{{ item.date }}</td>
                  <td>{{ item.documents.length }}</td>
                  <td>{{ item.status }}</td>

                  <td>
                    <v-btn
                      class="ma-2"
                      outlined
                      color="indigo"
                      @click="readNotification(item.date)"
                    >
                      View
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>

        <v-card v-if="notification" elevation="1" class="mb-1">
          <v-data-table
            :headers="headers"
            :items="compRawData"
            :search="search"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.createdOn="{ item }">
              {{ friendlyDate(item.createdOn) }}
            </template>
            <template v-slot:item.id="{ item }">
              <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="logActivity(item.title)"
                :href="item.URL"
                target="_blank"
              >
                Download
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <v-card
          v-if="role === 'admin' || role === 'corporate'"
          elevation="1"
          class="mb-12"
        >
          <v-card-title>Manual Notification</v-card-title>
          <v-card-subtitle
            >As an admin, you may manually send a notification to users based on
            their role.</v-card-subtitle
          >
          <v-row class="px-4 pb-6">
            <v-col
              ><v-textarea
                name="input-7-1"
                label="Notification message"
                v-model="notificationContent"
                hint="Enter the notification message here"
              ></v-textarea>
              <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="sendManualNotification"
              >
                Send
              </v-btn>
            </v-col>
            <v-col>
              <label for="recipients">Recipients</label>
              <v-checkbox
                v-model="recipients"
                label="All"
                value="All"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="recipients"
                v-for="role in roles"
                v-bind:key="role"
                :label="role"
                :value="role"
                hide-details
              ></v-checkbox>
            </v-col>
            <!-- <v-col>
              <v-text-field
                v-model="testPhone"
                append-icon="mdi-phone"
                label="Phone"
                single-line
                hide-details
                clearable
              ></v-text-field
            ></v-col> -->
            <!-- <v-col>
              
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from "@/plugins/helpers";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import moment from "moment";
// import rawData from "@/assets/MOCK_DATA.json";
import ToggleFavorite from "@/components/ToggleFavorite.vue";
export default {
  name: "Notifications",
  components: {
    ToggleFavorite
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Title", value: "title" },
      { text: "Category", value: "category" },
      { text: "Date", value: "createdOn" },
      { text: "Uploaded By", value: "author" },
      { text: "", align: "end", value: "id", filterable: false }
    ],
    filterStatusItems: ["read", "unread"],
    filterStatus: "",
    dateRange: "",
    dateRanges: [
      "Last 7 Days",
      "Last 30 Days",
      "Last 6 Months",
      "Last 12 Months"
    ],
    valid: true,
    hideUI: false,
    rules: {
      required: value => !!value || "Required."
    },
    headersNotifications: [
      { text: "Notification Date", value: "date" },
      { text: "New Documents", value: "documents.length" },
      { text: "Status", value: "status" },
      { text: "", align: "end", value: "id", filterable: false }
    ],
    notification: "",
    notifications: [],
    testDate: "",
    testDates: [],
    testPhone: "",
    testEmail: "",
    notificationContent: "",
    users: [],
    recipients: []
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    roles() {
      let users = this.users;
      const unique = [...new Set(users.map(item => item.role))];
      return unique;
    },
    compRawData() {
      let data = this.$store.state.documents;
      // filter on selected notification date
      // TEMP for DEMO: get ids in selected notification
      let documents = [];
      // let resp = [];
      let notifications = this.notifications;
      if (notifications.length >= 1) {
        notifications = notifications.filter(n => n.date === this.notification);
        documents = notifications[0].documents;
      }

      // compare two arrays of objects and match via id
      const a3 = documents.map(t1 => ({
        ...t1,
        ...data.find(t2 => t2._id === t1._id)
      }));
      // console.log(a3);
      return a3;
    },
    compAuthors() {
      let documents = this.$store.state.documents;
      const unique = [...new Set(documents.map(item => item.author))];
      return unique;
    },
    compNotifications() {
      let n = this.notifications;
      // filter on read/unread
      if (this.filterStatus) {
        n = n.filter(d => d.status === this.filterStatus);
      }

      if (this.dateRange) {
        let dateStart = moment();
        let dateEnd = moment();
        switch (this.dateRange) {
          case "Last 7 Days":
            dateStart = moment().subtract(7, "days");
            break;
          case "Last 30 Days":
            dateStart = moment().subtract(30, "days");
            break;
          case "Last 6 Months":
            dateStart = moment().subtract(6, "months");
            break;
          case "Last 12 Months":
            dateStart = moment().subtract(12, "months");
            break;
        }
        n = n.filter(d => moment(d.date).isBetween(dateStart, dateEnd));
      }
      // filter on author
      if (this.author) {
        data = data.filter(d => d.author === this.author);
      }

      return n;
    }
    // compNotificationDates() {
    //   let n = this.notifications;
    //   let dates = [];
    //   n.forEach((notification) => dates.push(notification.date));
    //   return dates;
    // },
  },
  methods: {
    readNotification(date) {
      let vm = this;
      // set active notificate to selected date
      this.notification = date;

      // update user unread date array
      let unread = this.$store.state.user.unread;
      // console.log(unread);

      if (unread != 0 && unread.indexOf(date) > -1) {
        let i = unread.indexOf(date);
        // remove date from array of unread dates
        let deleted = unread.splice(i, 1);
        vm.$store.commit("updateNotificationUnread", {
          unread
        });
        // update notification counter badge
        vm.$store.commit("updateNotification", {
          notifications: unread.length
        });
        // update user document via api call
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios.put(vm.$root.urls.api + "/user/notification", {
          unread
        });
      }

      // mark as read status
      let notifications = this.notifications;
      for (let index = 0; index < notifications.length; index++) {
        if (notifications[index].date === date) {
          notifications[index].status = "read";
        }
      }
      this.notifications = notifications;
    },
    formatStatus(item, status) {
      if (status === "unread") {
        return `<strong>${item}</strong>`;
      }
      return item;
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    logActivity(title, action = "Downloaded") {
      let vm = this;
      // log this activity
      let activity = {
        user: {
          username: `${this.$store.state.user.firstname} ${this.$store.state.user.lastname}`,
          email: this.$store.state.user.username
        },
        date: moment().format("MM/DD/YYYY"),
        action: `${action}: ${title} `
      };
      helpers.log(vm.token, vm.$root.urls.api, activity);
      return;
    },
    // sendTestNotification() {
    //   let vm = this;
    //   const date = moment(this.testDate).format("MMDDYYYY");
    //   const email = this.testEmail;
    //   const phone = this.testPhone;
    //   // TEMP parse document mock data based on date
    //   this.notification = this.testDate;
    //   const documents = this.compRawData;
    //   // console.log(documents);

    //   axios.defaults.headers.common = {
    //     Authorization: `Bearer ${vm.token}`,
    //   };
    //   if (date && email) {
    //     axios
    //       .post(vm.$root.urls.api + "/notification/email", {
    //         date,
    //         email,
    //         documents,
    //       })
    //       .then(function (results) {
    //         vm.$toasted.show("Test notification email sent").goAway(2500);
    //       });
    //   }
    //   if (date && phone) {
    //     axios
    //       .post(vm.$root.urls.api + "/notification/sms", {
    //         date,
    //         phone,
    //       })
    //       .then(function (results) {
    //         vm.$toasted.show("Test notification SMS sent").goAway(2500);
    //       });
    //   }
    // },
    sendManualNotification() {
      let vm = this;
      const message = this.notificationContent;
      const email = this.testEmail;
      const phone = this.testPhone;
      const recipients = this.recipients;

      // ensure at least one role has been selected
      if (vm.recipients.length) {
        // console.log('gonna send!');
        axios.defaults.headers.common = {
          Authorization: `Bearer ${vm.token}`
        };
        axios
          .post(vm.$root.urls.api + "/notification/manual", {
            message,
            email,
            phone,
            recipients
          })
          .then(function(results) {
            vm.$toasted.show("Manual notification sent").goAway(2500);
          });
      } else {
        vm.$toasted
          .show("Please select at least one recipient", {
            type: "error",
            icon: "mdi-alert-circle"
          })
          .goAway(2500);
      }
    },
    buildNotifications() {
      // init notifications
      this.notifications = [];
      // set todays date
      let today = moment();
      let counter = 30;

      // loop past 30 days
      for (let index = 0; index < counter; index++) {
        // update date to check
        let dateCheck = today.subtract(1, "days");
        let dayOfWeek = moment(dateCheck).format("dd");
        // console.log(dayOfWeek);
        switch (
          moment()
            .subtract(index, "days")
            .format("dd")
        ) {
          // if T W Th F, check previous day
          case "Tu":
          case "We":
          case "Th":
          case "Fr":
            this.queryDocsByDate(moment(dateCheck).format("MM/DD/YYYY"));
            break;
          // if M, check F S Su
          case "Mo":
            let range = true;
            this.queryDocsByDate(moment(dateCheck).format("MM/DD/YYYY"), range);
            break;
        }
      }
    },
    queryDocsByDate(date, range = false) {
      // init documents
      let documents = this.$store.state.documents;
      // query documents by date
      if (range === false) {
        var dateStart = moment.utc(date).startOf('day');
        var dateEnd = moment.utc(date).endOf('day');
        // var dateEnd = moment(date).add(1, "days");
      }
      if (range === true) {
        // query documents by date range (if Monday, we check for Fr Sa Su)
        var dateStart = moment.utc(date).subtract(2, "days").startOf('day');
        var dateEnd = moment.utc(date).endOf('day');
        // var dateStart = moment(date).subtract(2, "days");
        // var dateEnd = moment(date).add(1, "days");
      }
      // if (range === false) {
      //   var dateStart = moment(date);
      //   var dateEnd = moment(date).add(1, "days");
      // }
      // if (range === true) {
      //   // query documents by date range (if Monday, we check for Fr Sa Su)
      //   var dateStart = moment(date).subtract(2, "days");
      //   var dateEnd = moment(date).add(1, "days");
      // }
      documents = documents.filter(d => moment.utc(d.createdOn).isBetween(dateStart, dateEnd));
      if (documents.length) {
        // calculate notification date. this is tricky due to day of week logic.
        if (range === true) {
          var notificationDate = moment(date)
            .add(1, "days")
            .format("MM/DD/YYYY");
        } else {
          var notificationDate = moment(date)
            .add(1, "days")
            .format("MM/DD/YYYY");
        }
        let notification = {
          date: notificationDate,
          status: "read",
          documents: []
        };
        let unread = this.$store.state.user.unread;
        if (unread.indexOf(notificationDate) > -1) {
          notification.status = "unread";
        }
        documents.forEach(d => {
          notification.documents.push({ _id: d._id });
        });
        this.notifications.push(notification);
      }
    },
    updateAllAsRead() {
      // allows a user to mark all notifications as read.
      let vm = this;
      let unread = [];
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .put(vm.$root.urls.api + "/user/notification", {
          unread
        })
        .then(function() {
          vm.$store.commit("updateNotification", {
            notifications: 0
          });
          vm.$store.commit("updateNotificationUnread", {
            unread
          });
          vm.buildNotifications();
        });
    }
  },
  mounted: function() {
    let vm = this;
    // query api for users
    if (vm.role === "admin" || vm.role === "corporate") {
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
      axios.get(vm.$root.urls.api + "/users", {}).then(function(results) {
        vm.users = results.data;
      });
    }

    // build notifications for the past 30 days
    this.buildNotifications();
    // parse any optional parameters passed in router
    if (this.$route.params.category) {
      this.searchCategory = this.$route.params.category;
    }
    // check for stored notification date, in case this page was accessed via a notification URL
    if (this.$store.state.notificationDate) {
      // hide notification intro and filter UI
      this.hideUI = true;
      // format date parameter as standard date
      let date = moment(this.$store.state.notificationDate, "MMDDYYYY");
      // console.log(date.format("MM/DD/YYYY"));
      this.readNotification(date.format("MM/DD/YYYY"));
      // reset stored notification date:
      this.$store.commit("updateNotificationDate", {
        notificationDate: ""
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.unread {
  font-weight: bold;
}
</style>
